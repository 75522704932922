import { useState } from 'react';
import axiosClient from '../../config/axios-interceptors';

const CheckingPriceBySeries = () => {
  const [series, setSeries] = useState('');
  const [total, setTotal] = useState(0);
  const [error, setError] = useState('');

  const onSeriesInputChange = (event) => {
    setSeries(event.target.value);
  };

  const onClickSeriesCheck = async (event) => {
    const response = await axiosClient.post('/checking-price-by-series', {
      series: series
    });

    if (response.data.price > 0) {
      setTotal(response.data.price);
    } else {
      setTotal(0);
      setError('Неправильная серия (штрихкод)')
    }

    event.preventDefault();
  };

  const onClickDownloadCheck = () => {
    
  }

  return (
    <div className="max-w-sm mx-auto">
      <h1 className="text-3xl mb-2">Проверь меня</h1>
      <img src="barcode.png" />
      <div className="mb-5 mt-3">
        <label htmlFor="series" className="block mb-2 font-medium text-gray-900 dark:text-white">
          Введите серию (штрихкод) изделия
        </label>
        <input
          type="text"
          id="series"
          onChange={onSeriesInputChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
      <button
        onClick={onClickSeriesCheck}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Проверить
      </button>
      {total > 0 ? (
        <>
          <p id="total" className="my-4">
            Сумма изделия: {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'KZT' }).format(total)}
          </p>
          <button
            onClick={onClickDownloadCheck}
            className="border bg-white-700 hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            disabled
          >
            Скачать чек
          </button>
        </>
      ) : <p id="total" className="my-4 text-red-500">{error}</p> }
    </div>
  );
};

export default CheckingPriceBySeries;
