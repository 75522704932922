import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axiosClient from "./axios-interceptors";
import Consultant from "../veiws/Consultant/Consultant";

const ConsultantRoute = () => {
    const [isConsultant, setIsConsultant] = useState(null);

    useEffect(() => {
        const checkRole = async () => {
            try {
                const response = await axiosClient.get('/user-role');
                setIsConsultant(response.data.role === 'consultant');
            } catch (error) {
                console.error('Ошибка проверки роли пользователя:', error);
                setIsConsultant(false);
            }
        };

        checkRole();
    }, []);

    if (isConsultant === null) {
        return <div>Проверка доступа...</div>;
    }

    return isConsultant ? <Consultant /> : <Navigate to="/login" />;
};

export default ConsultantRoute;
