import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axiosClient from "../../../../config/axios-interceptors";
import OrderItem from './OrderItem';
import Loader from "../../../../components/Loader";

const OrderDetailsComponent = ({ order, onClose }) => {
    const { id } = useParams();
    const [orderItems, setOrderItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrderItems = async () => {
            setLoading(true);
            try {
                const response = await axiosClient.get(`/orderItems?order_id=${id}`);
                setOrderItems(response.data);
            } catch (error) {
                console.error('Error fetching order items:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderItems();
    }, [id]);

    if (loading) {
        return <Loader/>;
    }

    return (
        <div className={"w-full h-full flex items-center justify-center"}>
            <div className={"w-[80%] grid grid-cols-4 gap-4"}>
                {orderItems.map(item => (
                    <OrderItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default OrderDetailsComponent;