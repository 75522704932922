import React, {useState, useEffect, useRef, useContext} from 'react';
import Slider from 'react-slider';
import Checkbox from './ProductSidebarComponents/Checkbox';
import {toast} from 'react-toastify';
import catalog from '../../Catalog/Catalog';
import {CatalogContext} from '../../../contexts/CatalogContext';
import {TiArrowSortedDown} from "react-icons/ti";
import {TiArrowSortedUp} from "react-icons/ti";
import useProductsStore from "../../../store/ProductStore";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

const ProductsSidebar = ({
                             setMaxPrice,
                             setMinPrice,
                             minPrice,
                             maxPrice,
                             selecteds,
                             setSelecteds,
                             onPriceChange,
                             products,
                             onFilterProducts,
                             setLoading,
                             goldType,
                             setGoldType,
                             inserts,
                             setInserts
                         }) => {
    const [materialOpen, setMaterialOpen] = useState(true);
    const [priceOpen, setPriceOpen] = useState(true);
    const [subtypeOpen, setSubtypeOpen] = useState(true);
    const [sizeOpen, setSizeOpen] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [goldTypeOpen, setGoldTypeOpen] = useState(true);
    const [localMinPrice, setLocalMinPrice] = useState(minPrice);
    const [localMaxPrice, setLocalMaxPrice] = useState(maxPrice);
    const debounceTimeout = useRef(null);
    const {catalogId} = useContext(CatalogContext);

    const catalogNames = {
        1: 'Браслеты',
        2: 'Кольца',
        3: 'Серьги',
        4: 'Цепи',
        null: 'Все'
    };
    const goldNames = {
        yellowgold: 'Желтое золото',
        redgold: 'Красное золото',
        whitegold: 'Белое золото',
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    const handleSliderChange = (value) => {
        setLocalMinPrice(value[0]);
        setLocalMaxPrice(value[1]);
    };

    const handleSliderAfterChange = (value) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
        setLoading(true);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            onPriceChange(value);
            setLoading(false);
        }, 2000);
    };

    const handleCheckboxChange = (value) => {
        if (selecteds.length >= 10 && !selecteds.includes(value)) {
            toast.info('Вы не можете выбрать больше 10 элементов');
            return;
        }
        const newValue = typeof value === 'string' ? parseFloat(value) : value;
        const newSelecteds = selecteds.includes(newValue)
            ? selecteds.filter((item) => item !== newValue)
            : [...selecteds, newValue];
        setSelecteds(newSelecteds);
    };

    const handleGoldTypeChange = (type) => {
        setGoldType(type === goldType ? '' : type);
    };

    const handleInsertsChange = (insert) => {
        const newInserts = inserts.includes(insert)
            ? inserts.filter((item) => item !== insert)
            : [...inserts, insert];
        setInserts(newInserts);
    };

    useEffect(() => {
        switch (catalogId) {
            case 1:
                setSizes(['16', '16.5', '17', '17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21', '21.5', '22']);
                break
            case 2:
                setSizes(['16', '16.5', '17', '17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21', '21.5', '22']);
                break;
            case 3:
                setSizes(['17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21']);
                break;
            case 4:
                setSizes(['50', '50.5', '51', '51.5', '52', '52.5', '53', '53.5', '54', '54.5', '55', '55.5', '56', '56.5', '57', '57.5', '58', '58.5', '59', '59.5', '60']);
                break;
        }
    }, [catalogId]);

    const uniqueInserts = ['Фианит', 'Сапфир', 'Рубин', 'Изумруд', 'Гранат'];
    const materials = ['Красное золото', 'Желтое золото', 'Белое золото'];

    return (
      <div className="p-0 mx-4 mt-10 lg:mt-[70px] sm:mx-2 sm:p-4 w-[20%] 2xl:min-w-[25%]">
        <div className="hidden md:flex items-start font-bold relative top-[-50px] -left-1">
          <span className={'text-5xl 2xl:text-6xl'}>
            {' '}
            {goldType.length ? goldNames[goldType] : catalogNames[catalogId]}
          </span>
        </div>
        <div className="mb-6">
          <button
            onClick={() => setGoldTypeOpen(!goldTypeOpen)}
            className="flex items-center w-full font-sans text-xl font-semibold mb-4 "
          >
            <span className={'text-customGray font-bold text-3xl mr-1'}>Материал</span>
            {goldTypeOpen ? (
              <MdKeyboardArrowDown className={'size-9 text-black'} />
            ) : (
              <MdKeyboardArrowUp className={'size-9 text-black'} />
            )}
          </button>
          {goldTypeOpen && (
            <div className="">
              <label className="flex items-center mb-1">
                <Checkbox checked={goldType === 'redgold'} onChange={() => handleGoldTypeChange('redgold')} />
                <span className={'text-gray-600 text-md ml-2'}>Красное золото</span>
              </label>
              <label className="flex items-center mb-1">
                <Checkbox checked={goldType === 'yellowgold'} onChange={() => handleGoldTypeChange('yellowgold')} />
                <span className={'text-gray-600 text-md ml-2'}>Желтое золото</span>
              </label>
              <label className="flex items-center mb-1">
                <Checkbox checked={goldType === 'whitegold'} onChange={() => handleGoldTypeChange('whitegold')} />
                <span className={'text-gray-600 text-md ml-2'}>Белое золото</span>
              </label>
            </div>
          )}
        </div>

        <div className="mb-16 mt-10 w-[80%]">
          <button
            onClick={() => setPriceOpen(!priceOpen)}
            className="flex items-center w-full text-xl font-semibold mb-4"
          >
            <h1 className="text-customGray font-bold text-3xl mr-1">Цена</h1>
            {priceOpen ? (
              <MdKeyboardArrowDown className={'size-9 text-black'} />
            ) : (
              <MdKeyboardArrowUp className={'size-9 text-black'} />
            )}
          </button>
          {priceOpen && (
            <div className={'w-[100%] 2xl:w-[80%] ml-0'}>
              <Slider
                className="w-full h-[2px] flex items-center bg-gray-600 rounded-lg mt-6"
                thumbClassName="size-5 bg-black rounded-full cursor-pointer"
                trackClassName="bg-black"
                value={[localMinPrice, localMaxPrice]}
                min={21500}
                max={644000}
                step={1000}
                onChange={handleSliderChange}
                onAfterChange={handleSliderAfterChange}
              />

              <div className="flex w-full mt-2">
                <div className={'flex flex-col w-full'}>
                  <div
                    className="w-full mt-2 border-2 border-gray-600 rounded-xl p-2 pl-6 text-sm my-1"
                    style={{ outline: 'none', textAlign: 'left' }}
                  >
                    <span style={{ fontSize: '12px' }}>от </span>
                    <span style={{ fontSize: '17px' }}>{localMinPrice}</span>
                    <span style={{ fontSize: '12px' }}> ₸</span>
                  </div>
                  <div
                    className="w-full mt-2 border-2 border-gray-600 rounded-xl p-2 pl-6 text-sm"
                    style={{ outline: 'none', textAlign: 'left' }}
                  >
                    <span style={{ fontSize: '12px' }}>от </span>
                    <span style={{ fontSize: '17px' }}>{localMaxPrice}</span>
                    <span style={{ fontSize: '12px' }}> ₸</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-6 md:mt-8">
          <button
            onClick={() => setSubtypeOpen(!subtypeOpen)}
            className="flex items-center w-full text-xl font-semibold mb-4"
          >
            <span className={'text-customGray font-bold text-3xl mr-1'}>Вставка</span>
            {subtypeOpen ? (
              <MdKeyboardArrowDown className={'size-9 text-black'} />
            ) : (
              <MdKeyboardArrowUp className={'size-9 text-black'} />
            )}
          </button>
          {subtypeOpen && (
            <div className="">
              {uniqueInserts.map((insert) => (
                <label className="flex items-center mb-1" key={insert}>
                  <Checkbox checked={inserts.includes(insert)} onChange={() => handleInsertsChange(insert)} />
                  <span className={'text-gray-600 text-md ml-2'}>{insert}</span>
                </label>
              ))}
            </div>
          )}
        </div>

        <div className="mb-6">
          <button
            onClick={() => setSizeOpen(!sizeOpen)}
            className="flex items-center w-full text-xl font-semibold mb-4"
          >
            <span className={'text-customGray font-bold text-3xl mr-1'}>Размер</span>
            {sizeOpen ? (
              <MdKeyboardArrowDown className={'size-9 text-black'} />
            ) : (
              <MdKeyboardArrowUp className={'size-9 text-black'} />
            )}
          </button>
          {sizeOpen && (
            <div className="w-[60%] grid grid-cols-3 gap-1">
              {sizes.map((size) => (
                <button
                  key={size}
                  onClick={() => handleCheckboxChange(size)}
                  className={`
                        py-1.5 px-2 mb-1 text-center rounded-lg border text-gray-500
                        ${selecteds.includes(size) ? 'bg-gray-200 border-gray-400' : 'bg-white border-black'}
                        hover:border-gray-400 transition-colors duration-200
                    `}
                >
                  {size}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
};

export default ProductsSidebar;
