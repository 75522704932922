import React, {useContext} from 'react';
import {AuthUserContext} from "../../../contexts/AuthUserContext";

const DataComponent = () => {
    const {authUser} = useContext(AuthUserContext);
    return (
        <div className={"w-full"}>
            <h1 className={"text-2xl font-medium flex flex-start"}>Ваши личные данные</h1>
            <span className={"text-sm flex flex-start mt-10 mb-2"}>Номер телефона :</span>
            <div className={" border-black border-2 rounded-2xl text-sm px-10 py-2.5 focus:bg-gray-100"}>
                <span className={"text-lg flex flex-start"}>{authUser.phone}</span>
            </div>
        </div>
    );
};

export default DataComponent;