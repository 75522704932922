import React, {memo, useContext, useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import axiosClient from '../../config/axios-interceptors';
import Product from '../Products/components/Product';
import {SearchContext} from '../../contexts/SearchContext';
import {CatalogContext} from '../../contexts/CatalogContext';
import Loader from '../../components/Loader';
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {FavouritesSizeModal} from "../../components/FavouritesSizeModal";

const Favourites = () => {
        const [products, setProducts] = useState([]);
        const {searchValue} = useContext(SearchContext);
        const {isOpenCatalog, setIsOpenCatalog} = useContext(CatalogContext);
        const [loading, setLoading] = useState(true);
        const [isOpen, setIsOpen] = useState(false);
        const [isChecked, setIsChecked] = useState(false);
        const navigate = useNavigate();
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [selectedProduct, setSelectedProduct] = useState(null);
        const [selectedSize, setSelectedSize] = useState(null);

        useEffect(() => {
            axiosClient
                .get('/favourites')
                .then((response) => {
                    setProducts(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('There was an error fetching the favourites!', error);
                    setLoading(false);
                });
        }, []);

        const handleDelete = (id) => {
            console.log(id)
            axiosClient
                .delete(`/favourites/${id}`)
                .then((response) => {
                    setProducts(products.filter(product => product.product_id !== id));
                })
                .catch((error) => {
                    console.error('There was an error deleting the favourite!', error);
                });
        };
    const handleBasketClick = (product) => {
        if (product.size) {
            setSelectedProduct(product);
            setIsModalOpen(true);
        } else {
            addToBasket(product.product_id);
        }
    };


    const addToBasket = async (productId, size = null) => {
        try {
            const response = await axiosClient.post('/basket', {
                product_id: productId,
                quantity: 1,
                size: size,
            });
            console.log(response.data);
            toast.success('Добавлено в корзину');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Чтобы добавлять товар в корзину нужно авторизоваться!!');
        }
    };

        const deleteAll = async () => {
            products.forEach((item) => {
                handleDelete(item.product_id);
            });
        };

        const filteredProducts = products.filter((product) =>
            product.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        const formatSample = (sample) => {
            return sample.replace(/^Au /, '');
        };

        if (loading) return <Loader/>;


        if (products.length === 0) {
            return (
                <div className="w-full flex items-center justify-center">
                    <div className={'w-1/2 flex items-center'}>
                        <div className={'w-full flex items-center justify-center'}>
                            <img src="/catalogImages/избранное%20иллюстрация%20(1).png" className={"h-[400px]"}/>
                        </div>
                        <div className={'w-full h-1/2 flex flex-col items-center ml-30'}>
                            <span
                                className={'text-lg text-center text-customGray mt-2'}>Добавьте украшение в избранное -</span>
                            <button
                                className="font-bold text-customGray flex items-center justify-center px-5 py-2 border-2 border-black rounded-2xl mt-4 text-2xl"
                                onClick={() => setIsOpenCatalog(!isOpenCatalog)}
                            >
                                Перейти в каталог
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="w-full flex items-center justify-center mt-10">
                    <motion.div
                        initial={{y: -20}}
                        animate={{y: 0}}
                        transition={{duration: 0.5}}
                        className="w-full xl:w-[75%] flex items-center justify-between"
                    >
                        <h1 className="text-3xl">{products.length} изделия</h1>
                        <svg
                            onClick={() => setIsOpen(!isOpen)}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-9 hover:cursor-pointer"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </motion.div>
                </div>

                {isOpen && (
                    <div
                        className="origin-top-right relative left-0 md:left-[60%] lg:left-[70%] border-2 border-gray-400 w-full md:w-56 mt-5 md:mt-0 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 mb-0">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <button
                                className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                role="menuitem"
                                onClick={() => deleteAll()}
                            >
                                Очистить избранное
                            </button>
                        </div>
                    </div>
                )}

                {loading ? (
                    <Loader/>
                ) : filteredProducts.length > 0 ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 w-full xl:w-[80%]">
                                <AnimatePresence>
                                    {filteredProducts.map((product) => (
                                        <motion.div
                                            key={product.id}
                                            initial={{opacity: 0, y: 20}}
                                            animate={{opacity: 1, y: 0}}
                                            exit={{opacity: 0, y: -20}}
                                            transition={{duration: 0.5}}
                                            className="flex flex-col w-full items-center justify-center lg:flex-row mb-10 shadow-md sm:shadow-none rounded-2xl pb-2"
                                        >

                                            <div className="w-full lg:w-1/2 h-full bg-gray-100 rounded-2xl">
                                                <img
                                                    className="max-w-full max-h-full object-contain p-5 sm:p-10"
                                                    src="/catalogImages/111001157.2.png"
                                                    alt={product.name}
                                                />
                                            </div>

                                            <div className="w-full lg:w-1/3 h-full flex flex-col items-start justify-center">
                                                <h1 className="text-xl md:text-2xl flex flex-start ml-2 lg:ml-6 ">
                                                    {product.product_name.match(/[А-Яа-яЁё]+/)
                                                        ? product.product_name.match(/[А-Яа-яЁё]+/)[0]
                                                        : 'No Match'}
                                                </h1>
                                                <h1 className="text-lg md:text-2xl flex flex-start ml-2 lg:ml-6 ">      {formatSample(product.sample)} пробы
                                                </h1>
                                                <h1 className="text-md md:text-xl flex flex-start ml-2 lg:ml-6  text-gray-500">Арт. {product.article}</h1>
                                                <h1 className="text-md md:text-xl flex flex-start ml-2 lg:ml-6  text-gray-500">
                                                    <span className="mr-2">Цена: </span>{' '}
                                                    <span className="font-bold">
                                                        {Math.round(product.weight ? product.price * product.weight : (product.average_weight == 0 ? product.price : product.price * product.average_weight))} ₸
                                                    </span>
                                                </h1>

                                                <div className="w-full flex flex-col items-start">
                                                    <div className="w-[90%] sm:w-[90%] ml-2 lg:ml-6 ">
                                                        <motion.div
                                                            onClick={() => handleBasketClick(product)}
                                                            whileHover={{scale: 1.05}}
                                                            className="flex rounded-lg mt-2 hover:cursor-pointer items-center justify-center w-full p-1 bg-customGray"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlSpace="preserve"
                                                                width="26.6239mm"
                                                                height="25.0267mm"
                                                                className="size-5"
                                                                viewBox="0 0 77.18 72.55"
                                                            >
                                                                <defs>
                                                                    <style>{`
            .fil0 { fill: #ffffff; }
        `}</style>
                                                                </defs>
                                                                <g id="Слой_x0020_1">
                                                                    <path
                                                                        className="fil0"
                                                                        d="M-0 2.58c0,4.84 5.11,2.68 8.03,3.37 0.83,0.2 1.1,0.47 1.58,0.99 0.48,0.52 0.61,1.1 0.85,1.88 2.86,9.45 5.45,19.66 8.26,28.95 0.85,2.8 1.51,5.49 2.37,8.34 0.73,2.42 1.52,6.09 2.69,7.84 1.21,1.82 3.98,3.82 7.02,3.82l33.21 0c1.68,0 3.3,-0.82 4.38,-1.51 3.15,-2.04 3.52,-4.69 4.17,-7.85l3.77 -20.14c0.64,-3.3 1.82,-6.26 -0.63,-7.66 -1.19,-0.68 -9.04,-0.35 -10.77,-0.35 -14.9,0 -29.81,0 -44.71,0 -1.04,-2.1 -3.57,-13.87 -5.46,-16.52 -0.83,-1.16 -1.94,-2.14 -3.25,-2.79 -2.6,-1.29 -5.58,-0.9 -8.91,-0.9 -1.16,0 -2.6,1.4 -2.6,2.53zm50.46 63.82c0,6.29 6.84,7.7 10.16,4.47 3.32,-3.23 1.87,-9.88 -4.6,-9.88 -2.86,0 -5.57,2.63 -5.57,5.41zm-17.81 0c0,6.29 6.84,7.7 10.16,4.47 3.32,-3.23 1.87,-9.88 -4.6,-9.88 -2.86,0 -5.57,2.63 -5.57,5.41z"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span className="ml-4 font-bold text-amber-50">В корзину</span>
                                                        </motion.div>

                                                        <motion.div
                                                            whileHover={{scale: 1.05}}
                                                            className="flex items-center justify-center w-full p-1 mt-2 rounded-lg hover:cursor-pointer border border-black"
                                                            onClick={() => handleDelete(product.product_id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="currentColor"
                                                                className="size-6 hover:fill-gray-300"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                                />
                                                            </svg>
                                                            <span className="ml-4">Удалить</span>
                                                        </motion.div>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))}
                                    {isModalOpen && selectedProduct && (
                                        <FavouritesSizeModal
                                            sizes={[selectedProduct.size]}
                                            selectedSize={selectedSize}
                                            setSelectedSize={setSelectedSize}
                                            setIsModalOpen={setIsModalOpen}
                                            product={selectedProduct}
                                            addToBasket={addToBasket}
                                        />
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                    ) :
                    (
                        ''
                    )
                }
            </div>
        )
            ;
    }
;

export default memo(Favourites);
