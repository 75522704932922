import React from 'react';

const TiktokIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 73.57 73.57"
            className="size-9 ml-2 lg:ml-0"
        >
            <rect
                x="0"
                y="0"
                width="73.57"
                height="73.57"
                rx="8.33"
                ry="8.33"
                className="fill-customGray"
            />
            <path
                d="M54.82 32.76c-0.34,0.03 -0.69,0.05 -1.04,0.05 -3.8,0 -7.34,-1.91 -9.42,-5.09l0 17.33c0,7.08 -5.74,12.81 -12.81,12.81 -7.08,0 -12.81,-5.74 -12.81,-12.81 0,-7.08 5.74,-12.81 12.81,-12.81 0.27,0 0.53,0.02 0.79,0.04l0 6.31c-0.26,-0.03 -0.52,-0.08 -0.79,-0.08 -3.61,0 -6.54,2.93 -6.54,6.54 0,3.61 2.93,6.54 6.54,6.54 3.61,0 6.8,-2.85 6.8,-6.46l0.06 -29.44 6.04 0c0.57,5.42 4.94,9.65 10.37,10.04l0 7.02 -0.01 0z"
                className="fill-white"
            />
        </svg>
    );
};

export default TiktokIcon;
