import React from 'react';
import OrderChildComponent from "./OrderChildComponent";
import {useNavigate} from "react-router-dom";

const OrderComponent = ({order}) => {
    const navigate = useNavigate();

    return (
        <div>
           <div className={"w-full flex"}>
               <div className={"w-[75%] bg-grayLight py-6 px-4 rounded-2xl"}>
                   <img src={'https://back.almaray.kz/images/'+(order.items.length && order.items[0].picture_path ? order.items[0].picture_path : 'site/00000000.png')} alt=""/> 
                   {/*1 фото от order items не забыть!!*/}
               </div>
                <div className={"w-[25%] mx-2 flex flex-col justify-between"}>
                    {
                        order.items.slice(1, 4).map(orderItem => (
                            <OrderChildComponent orderItem={orderItem}/>
                        ))
                    }
                </div>
           </div>
            <div className={"w-[75%] mt-2"}>
                <span className={"text-xs font-bold flex flex-start ml-1"}>
  {new Date(order.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})}
</span>
                <span className={"text-xs font-bold flex flex-start ml-1"}>
  Всего: {order.items.length} {order.items.length === 1 ? 'изделие' : (order.items.length >= 2 && order.items.length <= 4 ? 'изделия' : 'изделий')}
</span>
                <span className={"text-xs font-bold flex flex-start ml-1"}>Общая сумма: {order.total_price} тг</span>
                <span
                    className={"text-customGray text-xs font-bold flex flex-start ml-1 hover:cursor-pointer hover:underline"}
                    onClick={() => navigate(`/orders/${order.id}`)}
                >Перейти ></span>
            </div>
        </div>
    );
};

export default OrderComponent;