import React from 'react';

const SendIcon = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                className={"h-6 w-6"}
                version="1.1"
                style={{
                    shapeRendering: 'geometricPrecision',
                    textRendering: 'geometricPrecision',
                    imageRendering: 'optimizeQuality',
                    fillRule: 'evenodd',
                    clipRule: 'evenodd'
                }}
                viewBox="0 0 1183.62 887.15"
            >
                <defs>
                    <style type="text/css">
                        {`
            .str0 {stroke:#666666;stroke-width:1.12;stroke-miterlimit:22.9256}
            .fil0 {fill:black}
          `}
                    </style>
                </defs>
                <g id="Слой_x0020_1">
                    <metadata id="CorelCorpID_0Corel-Layer"/>
                    <g id="_2897552401600">
                        <path
                            className="fil0"
                            d="M580.17 0c28.85,0 56.23,2.34 78.68,18.43l55.77 57.53c6.96,8.72 10.29,11.49 17.6,19.39 4.36,4.71 4.27,5.67 8.17,10.33 14.6,17.47 31.2,32.01 45.22,49.58 13.7,17.18 74.16,78.65 89.18,98.1 6.12,7.92 11.77,11.74 18.08,18.91l98.3 109.79c18.34,20.83 47.28,48.22 62.99,68.8 6.01,7.87 11.15,11.7 17.95,19.05l89.46 100.14c33.4,44.09 28.17,108.89 -10.95,141.43 -32.66,27.16 -79.91,37.34 -119.65,16.92l-25.35 -18.59c-5.52,-4.84 -2.79,-2.73 -7.68,-8.51l-28.72 -31.4c-6.04,-5.99 -9.56,-9.12 -14.48,-15.57 -5.45,-7.14 -9.48,-10.09 -15.63,-16.73l-99.41 -110.99c-5.4,-6.14 -10.77,-9.98 -16.06,-16.31l-115.31 -127.46c-13.25,-17.16 -29.01,-30.96 -43.15,-47.02 -5.76,-6.55 -7.19,-9.64 -13.88,-16.18 -6.81,-6.66 -8.07,-8.99 -13.99,-16.07 -8.52,-10.19 -40.58,-39.26 -43.25,-49.24l-4.62 0c-2.02,7.58 -21.11,26.03 -27.18,32.93l-28.87 31.24c-24.77,24.21 -46.87,53.93 -71.72,78.57 -6.5,6.45 -8,9.97 -13.81,16.25 -5.18,5.6 -10.03,9.22 -14.95,15.1l-86.86 95.8c-6.05,6.28 -7.52,9.96 -13.78,16.28l-101.76 110.96c-22.85,25.95 -45.26,56.9 -75.25,75.04 -40.04,24.21 -87.17,13.02 -121.08,-14 -8.89,-7.08 -16.53,-17.84 -22.03,-28.83 -18.36,-36.62 -16.19,-78.9 8.8,-112.29l107.42 -119.17c6.92,-8.96 10.88,-11.51 17.72,-19.27l89.08 -98.2c31.16,-31.44 57.56,-67.09 89,-98.28l179.15 -197.72c20.74,-20.63 42.38,-38.73 76.88,-38.73z"
                        />
                        <path
                            className="fil0 str0"
                            d="M586.13 525.72c14.07,0 27.43,1.14 38.38,8.99l27.2 28.06c3.39,4.25 5.02,5.61 8.59,9.46 2.13,2.3 2.08,2.77 3.98,5.04 7.12,8.52 15.22,15.61 22.06,24.18 6.68,8.38 36.17,38.36 43.5,47.85 2.98,3.86 5.74,5.73 8.82,9.23l47.95 53.55c8.95,10.16 23.06,23.52 30.72,33.56 2.93,3.84 5.44,5.71 8.75,9.29l43.63 48.85c16.29,21.51 13.74,53.11 -5.34,68.98 -15.93,13.25 -38.98,18.21 -58.36,8.25l-12.36 -9.07c-2.7,-2.36 -1.36,-1.33 -3.74,-4.15l-14.01 -15.31c-2.95,-2.92 -4.66,-4.45 -7.07,-7.6 -2.66,-3.48 -4.62,-4.92 -7.63,-8.16l-48.49 -54.14c-2.64,-2.99 -5.25,-4.87 -7.83,-7.95l-56.25 -62.17c-6.46,-8.37 -14.15,-15.1 -21.05,-22.93 -2.81,-3.19 -3.51,-4.7 -6.77,-7.89 -3.32,-3.25 -3.94,-4.39 -6.82,-7.84 -4.15,-4.97 -19.8,-19.15 -21.1,-24.02l-2.25 0c-0.99,3.7 -10.3,12.7 -13.26,16.06l-14.08 15.24c-12.08,11.81 -22.86,26.3 -34.98,38.32 -3.17,3.15 -3.9,4.86 -6.74,7.92 -2.53,2.73 -4.89,4.5 -7.29,7.37l-42.37 46.73c-2.95,3.06 -3.67,4.86 -6.72,7.94l-49.64 54.12c-11.15,12.66 -22.08,27.75 -36.71,36.6 -19.53,11.81 -42.52,6.35 -59.06,-6.83 -4.33,-3.45 -8.06,-8.7 -10.75,-14.06 -8.95,-17.86 -7.9,-38.49 4.29,-54.77l52.39 -58.13c3.37,-4.37 5.3,-5.61 8.64,-9.4l43.45 -47.9c15.2,-15.33 28.08,-32.72 43.41,-47.94l87.39 -96.44c10.12,-10.06 20.67,-18.89 37.5,-18.89z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default SendIcon;