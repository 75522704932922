import React from 'react';

const Questions = () => {
    return (
        <div>
            Вопросы и ответы

        </div>
    );
};

export default Questions;