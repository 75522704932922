import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import Skrepka from "../icons/Skrepka";
import SendIcon from "../icons/SendIcon";
import { AuthUserContext } from "../contexts/AuthUserContext";
import axiosClient from "../config/axios-interceptors";

const VirtualAssistant = ({ isOpen, onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { authUser } = useContext(AuthUserContext);
    const [faq, setFaq] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (!isOpen) return;

        axiosClient.get("/faq")
            .then((response) => {
                setFaq(response.data.faq);
            })
            .catch((error) => console.error('Ошибка загрузки FAQ:', error));
    }, [isOpen]);

    useEffect(() => {
        // Прокручиваем вниз, когда появляется новый ответ
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [selectedAnswer]);

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handlePaperclipClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleQuestionClick = (answer) => {
        setSelectedAnswer(answer);
    };

    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.3 }}
            className=" hidden fixed flex w-full h-[70%] items-center justify-end top-[28%] right-32 z-50"
        >
            <div className="bg-white rounded-3xl shadow-[0_35px_60px_5px_rgba(0,0,0,0.3)] w-[30%] h-full z-10">
                <div className="flex rounded-t-3xl overflow-y-auto justify-around items-center mb-4 bg-customGray py-5">
                    <h2 className="text-2xl ml-2 text-white font-bold font-sans">Виртуальный ассистент</h2>
                    <div className={'w-9 h-9 bg-white rounded-full flex items-center justify-center hover:cursor-pointer'} onClick={onClose}>
                        <button onClick={onClose} className="text-customGray hover:text-gray-700">
                            <svg
                                className="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="w-full flex items-center justify-center my-10">
                    <img src={`${process.env.PUBLIC_URL}/ALMARAY.png`} alt="ALMARAY logo" className="w-1/2" />
                </div>

                <div className="w-full flex flex-col overflow-hidden">
                    <div className="w-full">
                        {/* Вопросы с кнопками */}
                        <div className="w-full p-4 overflow-y-auto h-[210px]">
                            {faq.map((item, index) => (
                                <button
                                    key={index}
                                    className="bg-gray-200 text-customGray text-lg w-full py-2 px-4 rounded-md mb-2 hover:bg-gray-300"
                                    onClick={() => handleQuestionClick(item.answer)}
                                >
                                    {item.question}
                                </button>
                            ))}
                            <button className="bg-gray-200 text-lg text-customGray w-full py-2 px-4 rounded-md mb-2 hover:bg-gray-300">
                                Связаться с службой поддержки.
                            </button>
                        </div>
                    </div>

                    {/* Контейнер для ответов */}
                    <div
                        className="w-full p-4 space-y-4 overflow-y-auto"
                        ref={chatContainerRef}
                    >
                        {selectedAnswer && (
                            <motion.div
                                className="bg-gray-100 p-4 rounded-lg text-black"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.3 }}
                            >
                                <p>{selectedAnswer}</p>
                            </motion.div>
                        )}
                    </div>
                </div>

                <div className="border-t-4 border-customGray px-4 pt-4 mx-4">
                    <div className="flex items-center">
                        <button onClick={handlePaperclipClick}>
                            <Skrepka />
                        </button>
                        <input
                            id="fileInput"
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                            multiple
                        />
                        <input
                            type="text"
                            placeholder="Введите сообщение"
                            className="flex-grow pl-8 text-lg text-customGray rounded-lg px-2 py-3 border-none focus:border-none focus:outline-none"
                        />
                        <button className="px-4 py-1 rounded-r">
                            <SendIcon />
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default VirtualAssistant;
