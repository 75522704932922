import React from 'react';

const Skrepka = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                className={"w-7 h-7 pl-3"}
                version="1.1"
                style={{
                    shapeRendering: 'geometricPrecision',
                    textRendering: 'geometricPrecision',
                    imageRendering: 'optimizeQuality',
                    fillRule: 'evenodd',
                    clipRule: 'evenodd'
                }}
                viewBox="0 0 259.96 441.64"
            >
                <defs>
                    <style type="text/css">
                        {`
            .fil0 { fill: black; }
          `}
                    </style>
                </defs>
                <g id="Слой_x0020_1">
                    <metadata id="CorelCorpID_0Corel-Layer"/>
                    <path
                        className="fil0"
                        d="M66.96 98.77l0 200.88c0,22.61 10.45,38.31 25.03,49.81 17.53,13.83 43.4,16.23 63.61,6.34 19.19,-9.38 35.43,-29.87 35.43,-52.21l0 -159.52c0,-3.39 -3.52,-9.12 -5.29,-11.45 -2.13,-2.8 -6.17,-5.61 -10.08,-6.68 -11.21,-3.07 -18.08,0.6 -24.3,7.01 -7.1,7.33 -5.63,15.36 -5.63,28.84 0,43.57 0.94,94.65 0.05,136.92 -0.53,24.9 -32.54,21.55 -32.54,1.92l0 -208.76c0,-35.55 51.27,-64.87 84.46,-32.27 8.57,8.41 15.98,20.48 15.98,33.26l0 223.53c0,25.57 -15.84,51.38 -36.43,65 -26.49,17.51 -57.12,19.83 -85.69,4.94 -20.99,-10.94 -34.36,-28.81 -41.72,-50.84 -4.56,-13.66 -3.56,-25.29 -3.56,-42.73 0,-30.85 0,-61.71 0,-92.56 0,-20.8 4.14,-52.24 -6.6,-63.31 -15.81,-16.29 -39.68,-4.56 -39.68,17.03l0 153.62c0,55.89 29.24,97.46 72.72,120.28 42.27,22.18 95.64,17.13 133.8,-11.25 7.07,-5.26 11.13,-9.15 17.26,-15.24 20.24,-20.12 36.19,-52.64 36.19,-92.81l0 -208.76c0,-25.07 -9,-51.7 -25.83,-68.7 -31.27,-31.59 -73.13,-40.23 -112.69,-20.58 -31.24,15.52 -54.49,48.32 -54.49,88.3z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Skrepka;