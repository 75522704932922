import React from 'react';

const Brands = () => {
    return (
        <div>
            Брэнды
        </div>
    );
};

export default Brands;