import React, { useEffect, useState } from 'react';

const Consultant= () => {
    const [messages, setMessages] = useState([]);
    const [response, setResponse] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        const ws = new WebSocket('ws://127.0.0.1:6001');

        ws.onmessage = (event) => {
            try {
                const messageData = JSON.parse(event.data);
                if (messageData.type) {
                    setMessages((prevMessages) => [...prevMessages, messageData]);
                    console.log(
                        messageData
                    )
                }
            } catch (e) {
                console.error('Invalid JSON message:', event.data);
            }
        };

        return () => {
            ws.close();
        };
    }, []);

    const handleSendResponse = () => {
        const ws = new WebSocket('ws://127.0.0.1:6001');

        if (selectedUserId && response && ws) {
            const messageData = {
                userId: selectedUserId,
                type: 'response',
                message: response,
            };
            ws.send(JSON.stringify(messageData));
            setResponse('');
        }
    };





    return (
        <div>
            <h1>Consultant Dashboard</h1>
            {messages.map((message, index) => (
                <div key={index}>
                    <h2>User {message.userId}</h2>
                    <div>{message.message}</div>
                    <input
                        type="text"
                        placeholder="Введите ответ"
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                    />
                    <button onClick={() => setSelectedUserId(message.userId)}>Select User</button>
                    <button onClick={handleSendResponse}>Send Response</button>
                </div>
            ))}
        </div>
    );
};

export default Consultant;