import {useState} from 'react';

const Ringsizer = () => {
    const [size, setSize] = useState(19.5);
    const minSize = 15;
    const maxSize = 24;

    const handleChange = (e) => {
        setSize(parseFloat(e.target.value));
    };

    const calculateInnerSize = () => {
        const minPixels = 100;
        const maxPixels = 200;
        const range = maxSize - minSize;
        const pixelRange = maxPixels - minPixels;
        const pixelsPerUnit = pixelRange / range;
        return minPixels + (size - minSize) * pixelsPerUnit;
    };

    const innerSize = calculateInnerSize();

    return (
        <div className="w-full flex items-center justify-center">
            <div className="w-[90%]">
                <h1 className="font-bold text-4xl flex flex-start ml-2 lg:ml-28  mb-5">Онлайн-Cайзер</h1>
                <div className={"flex flex-col md:flex-row w-full"}>
                    <div className="flex w-full md:w-1/2 h-[500px]">
                        <div style={{
                            backgroundImage: 'url("/ringImages/bg-ring.jpg")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                             className="max-w-md mx-auto p-6 w-full  rounded-lg shadow-md">
                            <h2 className="text-md text-customGray mb-10 text-center">Приложите кольцо на экран
                                телефона</h2>
                            <div className="relative mb-6">
                                <div
                                    style={{
                                        width: '256px',
                                        height: '256px',
                                        backgroundImage: 'url("/ringImages/рисунок для кольца рамка.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}
                                    className="mx-auto rounded-full flex items-center justify-center overflow-hidden"
                                >
                                    <div
                                        style={{
                                            width: `${innerSize}px`,
                                            height: `${innerSize}px`,
                                        }}
                                        className="border-2 border-gray-400 rounded-full flex items-center justify-center bg-white bg-opacity-50"
                                    >
                                        <span className="text-4xl font-bold text-customGray">{size.toFixed(1)}</span>
                                    </div>
                                </div>
                            </div>
                            <input
                                type="range"
                                min={minSize}
                                max={maxSize}
                                step={0.1}
                                value={size}
                                onChange={handleChange}
                                className="w-[80%] h-4 rounded-lg shadow-2xl bg-white border border-customGray appearance-none cursor-pointer accent-customGray custom-range"
                            />
                            <p className="text-center mt-4 text-customGray">Двигайте ползунок до границ кольца</p>
                            <div className={"w-full flex items-center justify-center"}>
                                <img src="/ringImages/иконка%20шкала%20.png" className={"w-[59px] mt-2"} alt=""/>
                            </div>
                        </div>
                    </div>


                    <div className={"w-1/2 hidden md:block"}>
                        <h1 className={"font-bold text-xl text-customGray flex flex-start"}>Онлайн-сайзер работает
                            корректно
                            только</h1>
                        <span
                            className={"flex text-xl flex-start font-bold text-customGray"}>в мобильной версии сайта.</span>


                        <div className={"w-full flex mt-5"}>
                            <div className={"w-[40%]"}>
                                <img src="/ringImages/qr%20код.png" className={"w-[70%]"} alt=""/>
                                <div className="flex items-start text-xs mt-1">
                                    <div className="flex flex-col w-full mt-5">
                                        <span className={"flex flex-start"}>*Наведите камеру телефона</span>
                                        <span className={"flex flex-start"}>и отсканируйте Qr-код </span>
                                        <span className={"flex flex-start"}>для перехода на страницу</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full"}>
                                <img src="/ringImages/телефон.png" className={"w-full h-full"} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"w-full flex items-center justify-center mt-10 md:mt-0"}>
                    <div className={"w-[80%]"}>
                        <h1 className={"font-bold text-3xl flex flex-start mb-7 text-customGray"}>Как использовать:</h1>
                        <div className={"flex flex-col items-start mb-7"}>
                            <h1 className={"text-xl text-customGray"}><span
                                className={"text-2xl text-customGray font-bold"}>1.</span> Возьмите любое кольцо,
                                подходящее на палец, размер которого вам нужно узнать. </h1>
                            <span className={"text-xl text-customGray"}>Положите его в центральный круг.</span>
                        </div>
                        <div className={"flex flex-col items-start mb-7"}>
                            <h1 className={"text-xl text-customGray"}><span
                                className={"text-2xl text-customGray font-bold"}>2.</span> Двигайте бегунок до тех пор,
                                пока границы круга не соприкоснутся с кольцом.
                            </h1>
                            <span className={"text-xl text-customGray"}>Цифра в центре и есть необходимый размер.</span>
                        </div>
                        <span className={"text-customGray text-sm flex flex-start"}>* В зависимости от типа устройства, онлайн-сайзер может давать минимальную погрешность.</span>
                    </div>

                </div>

                <div className={"w-full flex items-center justify-center mt-10"}>
                    <div className={"w-full md:w-[80%]"}>
                        <h1 className={"text-2xl text-customGray font-bold flex flex-start ml-0 md:ml-5 mb-5"}>Кольца в ассортименте под Ваш размер</h1>


                        <div className={"w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-5"}>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/обручальные%202.png" alt=""/>
                            </div>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/помолочные%202.png" alt=""/>
                            </div>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/национальные.png" alt=""/>
                            </div>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/мужские%202.png" alt=""/>
                            </div>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/кольца%20с%20камнями.png" alt=""/>
                            </div>
                            <div className={"mr-4 hover:cursor-pointer"}>
                                <img src="/ringImages/кольца%20от%2050.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ringsizer;