import React from 'react';

const OrderChildComponent = ({orderItem}) => {
    return (
        <div className={"w-full h-[32%] p-1 border-2 border-customGray rounded-lg"}>
            <img src={'https://back.almaray.kz/images/'+(orderItem.picture_path ? orderItem.picture_path : 'site/00000000.png')} alt=""/>
        </div>
    );
};

export default OrderChildComponent;