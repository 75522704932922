import './App.css';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AuthUserContext } from "./contexts/AuthUserContext";
import './config/axios-interceptors';
import ModalComponent from "./components/Modal";
import ChooseCity from "./components/ChooseCity";
import { CatalogContext } from "./contexts/CatalogContext";
import { ModalContext } from "./contexts/ModalContext";
import { CityContext } from "./contexts/CityContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from 'framer-motion';
import axios from "axios";
import axiosClient from "./config/axios-interceptors";
import { BasketContext } from "./contexts/BasketContext";
import { FavouriteContext } from "./contexts/FavouriteContext";
import AuthLoader from './config/auth-loader'
import { BookOpenIcon, HeartIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/16/solid";
import { HomeIcon } from "@heroicons/react/20/solid";
import useProductsStore from "./store/ProductStore";
import Catalog from "./veiws/Catalog/Catalog";
import AppRoutes from "./AppRoutes";

function App() {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
    const { isOpen, setIsOpen } = useContext(ModalContext);
    const { setCity } = useContext(CityContext);
    const { baskets, setBaskets } = useContext(BasketContext);
    const { favourites, setFavourites } = useContext(FavouriteContext);
    const location = useLocation();
    const navigate = useNavigate();
    const setCatalogId = useProductsStore(state => state.setCatalogId);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const city = localStorage.getItem("city") ? localStorage.getItem("city") : null;
        if (city) {
            setCity(city);
        } else {
            setCity('');
        }
    }, []);

    useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
            setAuthUser(JSON.parse(user));
        } else {
            setAuthUser(undefined);
        }
    }, []);

    useEffect(() => {
        setIsOpenCatalog(false);
    }, [location.pathname]);

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    useEffect(() => {
        const catalogId = localStorage.getItem('catalogId');
        if (catalogId) {
            setCatalogId(catalogId);
        }
    }, []);

    return (
        <div className="App font-sans">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <ModalComponent
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(!isOpen)}
                Component={ChooseCity}
            />
            <Header />
            <div className={`${location.pathname === '/products' ? '' : ''}  mx-auto ${isMobile ? 'px-4' : ''}`}>
                <AnimatePresence>
                    {isOpenCatalog && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="absolute top-0 lg:top-[170px] left-0 w-full bg-white z-40 shadow-md"
                        >
                            <Catalog />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className="flex">
                    <AuthLoader>
                        <AppRoutes />
                    </AuthLoader>
                </div>
                <nav className="fixed flex items-center bottom-0 left-0 right-0 bg-white border-t z-50 lg:hidden">
                    <div className="flex justify-around items-center h-16 max-w-md mx-auto">
                        <NavItem icon={<HomeIcon className="h-6 w-6" onClick={() => navigate('/')} />} label="Главная" />
                        <NavItem icon={<BookOpenIcon className="h-6 w-6" onClick={() => setIsOpenCatalog(!isOpenCatalog)} />} label="Каталог" />
                        <NavItem icon={<HeartIcon className="h-6 w-6" onClick={() => navigate('/favourites')} />} label="Избранное" />
                        <NavItem icon={<ShoppingCartIcon className="h-6 w-6" onClick={() => navigate('/basket')} />} label="Корзина" />
                        <NavItem icon={<UserIcon className="h-6 w-6" onClick={() => navigate(`${authUser ? '/me' : '/login'}`)} />} label={`${authUser ? 'Профиль' : 'Войти'}`} />
                    </div>
                </nav>
                <Footer />
            </div>
        </div>
    );
}

const NavItem = ({ icon, label }) => {
    return (
        <a href="#" className="flex flex-col items-center text-customGray hover:text-gray-700 mx-2">
            {icon}
            <span className="text-xs mt-1">{label}</span>
        </a>
    );
};

export default App;