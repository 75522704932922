import React from 'react';

const YoutubeIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 63.92 63.92"
            className="size-9 ml-2 lg:ml-0"
        >
            <rect
                x="0"
                y="0"
                width="63.92"
                height="63.92"
                rx="7.24"
                ry="7.24"
                className="fill-customGray"
            />
            <path
                d="M39.15 32.79l-10.64 5.26c-0.42,0.23 -1.83,-0.08 -1.83,-0.55l0 -10.8c0,-0.48 1.43,-0.78 1.85,-0.54l10.18 5.54c0.43,0.24 0.87,0.86 0.44,1.09zm13.95 -6.35c0,-5.12 -4.15,-9.28 -9.28,-9.28l-23.72 0c-5.12,0 -9.28,4.15 -9.28,9.28l0 11.04c0,5.12 4.15,9.28 9.28,9.28l23.72 0c5.12,0 9.28,-4.15 9.28,-9.28l0 -11.04z"
                className="fill-white"
            />
        </svg>
    );
};

export default YoutubeIcon;
