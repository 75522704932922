import React from 'react';

const PinterestIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 68.4 68.4"
            className={"size-9 ml-2 lg:ml-0"}
        >
            <rect
                x="0"
                y="0"
                width="68.4"
                height="68.4"
                rx="7.74"
                ry="7.74"
                className="fill-customGray"
            />
            <path
                d="M31.52 40.64c-0.04,0.12 -0.07,0.22 -0.1,0.33 -1.55,6.09 -1.73,7.44 -3.32,10.26 -0.76,1.34 -1.62,2.62 -2.57,3.83 -0.11,0.14 -0.21,0.32 -0.42,0.27 -0.23,-0.05 -0.25,-0.26 -0.28,-0.45 -0.26,-1.85 -0.4,-3.7 -0.34,-5.57 0.08,-2.43 0.38,-3.27 3.52,-16.45 0.05,-0.2 -0.01,-0.37 -0.07,-0.55 -0.75,-2.02 -0.9,-4.08 -0.24,-6.16 1.42,-4.5 6.52,-4.84 7.41,-1.13 0.55,2.29 -0.9,5.3 -2.02,9.74 -0.92,3.66 3.39,6.27 7.07,3.59 3.4,-2.47 4.72,-8.38 4.47,-12.56 -0.49,-8.35 -9.65,-10.16 -15.46,-7.47 -6.66,3.08 -8.17,11.34 -5.17,15.11 0.38,0.48 0.68,0.77 0.55,1.26 -0.19,0.76 -0.36,1.52 -0.57,2.27 -0.16,0.56 -0.62,0.76 -1.19,0.53 -1.11,-0.45 -2.03,-1.15 -2.78,-2.08 -2.55,-3.16 -3.28,-9.42 0.09,-14.71 3.74,-5.87 10.7,-8.24 17.05,-7.52 7.59,0.86 12.38,6.05 13.28,11.93 0.41,2.68 0.12,9.29 -3.65,13.96 -4.33,5.37 -11.34,5.72 -14.58,2.43 -0.25,-0.25 -0.45,-0.55 -0.69,-0.85z"
                className="fill-white"
            />
        </svg>
    );
};

export default PinterestIcon;
