import React, { useContext, useEffect, useState } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';
import axiosClient from '../../../config/axios-interceptors';
import Loader from "../../../components/Loader";
import { MdKeyboardArrowDown } from "react-icons/md";
import OrderComponent from "./ChieldComponents/OrderComponent";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);
setDefaultLocale('ru');

const OrdersComponent = () => {
    const [orders, setOrders] = useState([]);
    const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const fetchOrders = async (startDate, endDate) => {
        setLoading(true);
        try {
            const response = await axiosClient.get('/orders', {
                params: {
                    start_date: startDate ? startDate.toISOString() : null,
                    end_date: endDate ? endDate.toISOString() : null,
                },
            });
            setOrders(response.data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchOrders(startDate, endDate);
    }, [startDate, endDate]);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setIsCalendarOpen(false);
    };

    if (loading) return <Loader />;

    return (
        <div className="w-full">
            {/* Заголовок */}
            <div className={"w-full flex items-center justify-end"}>
                <div
                    className={"flex items-center border-b-2 border-black hover:cursor-pointer"}
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                >
                    <span className={"font-bold text-xs mb-1.5 ml-7"}>Выбрать период</span>
                    <MdKeyboardArrowDown className={'size-4 text-black ml-1'} />
                </div>
                {isCalendarOpen && (
                    <div className="absolute top-[35%] flex space-x-4">
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            locale="ru"
                            dateFormat="dd.MM.yyyy"
                            inline
                        />
                    </div>
                )}
            </div>
            {/* Заголовок */}

            {/* Контент */}
            <div className={"w-full grid grid-cols-3 gap-3 mt-10"}>
                {orders.map(order => (
                    <OrderComponent key={order.id} order={order} />
                ))}
            </div>
            {/* Контент */}
        </div>
    );
};

export default OrdersComponent;