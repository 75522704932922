import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../index.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axiosClient from "../../config/axios-interceptors";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Main = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMobile] = useState(window.innerWidth <= 1100);

    useEffect(() => {
        axiosClient.get('/products/random').then((response) => {
            setProducts(response.data);
            setLoading(false);
        });
        window.scrollTo(0, 0);
    }, []);

    const productImages = [
        "/mainImages/геометрия.jpg",
        "/mainImages/золотой дуэт.jpg",
        "/mainImages/модерн.jpg",
        "/mainImages/узел любви.jpg",
        "/mainImages/удача.jpg"
    ];

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-arrow p-2`}
                style={{ ...style, display: "block", left: "10px" }}
                onClick={onClick}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18l-6-6 6-6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        );
    };

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-arrow p-2`}
                style={{...style, display: "block", right: "10px"}}
                onClick={onClick}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
                    viewBox="0 0 22.39 29.89"
                    className="w-6 h-8" // Пример классов Tailwind для размеров
                >
                    <defs>
                        <style type="text/css">
                            {`
          .fil0 { fill: black; }
        `}
                        </style>
                    </defs>
                    <g id="Слой_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer"/>
                        <g id="_2344681340896">
                            <path
                                className="fil0"
                                d="M-0 14.65c0,0.73 0.06,1.42 0.47,1.99l1.45 1.41c0.22,0.18 0.29,0.26 0.49,0.44 0.12,0.11 0.14,0.11 0.26,0.21 0.44,0.37 0.81,0.79 1.25,1.14 0.43,0.35 1.99,1.87 2.48,2.25 0.2,0.15 0.3,0.3 0.48,0.46l2.77 2.48c0.53,0.46 1.22,1.19 1.74,1.59 0.2,0.15 0.3,0.28 0.48,0.45l2.53 2.26c1.11,0.84 2.75,0.71 3.57,-0.28 0.69,-0.82 0.94,-2.02 0.43,-3.02l-0.47 -0.64c-0.12,-0.14 -0.07,-0.07 -0.21,-0.19l-0.79 -0.73c-0.15,-0.15 -0.23,-0.24 -0.39,-0.37 -0.18,-0.14 -0.25,-0.24 -0.42,-0.39l-2.8 -2.51c-0.15,-0.14 -0.25,-0.27 -0.41,-0.41l-3.22 -2.91c-0.43,-0.33 -0.78,-0.73 -1.19,-1.09 -0.17,-0.15 -0.24,-0.18 -0.41,-0.35 -0.17,-0.17 -0.23,-0.2 -0.41,-0.35 -0.26,-0.22 -0.99,-1.02 -1.24,-1.09l0 -0.12c0.19,-0.05 0.66,-0.53 0.83,-0.69l0.79 -0.73c0.61,-0.63 1.36,-1.18 1.98,-1.81 0.16,-0.16 0.25,-0.2 0.41,-0.35 0.14,-0.13 0.23,-0.25 0.38,-0.38l2.42 -2.19c0.16,-0.15 0.25,-0.19 0.41,-0.35l2.8 -2.57c0.66,-0.58 1.44,-1.14 1.89,-1.9 0.61,-1.01 0.33,-2.2 -0.35,-3.06 -0.18,-0.22 -0.45,-0.42 -0.73,-0.56 -0.92,-0.46 -1.99,-0.41 -2.84,0.22l-3.01 2.71c-0.23,0.17 -0.29,0.27 -0.49,0.45l-2.48 2.25c-0.79,0.79 -1.69,1.45 -2.48,2.25l-4.99 4.52c-0.52,0.52 -0.98,1.07 -0.98,1.94z"
                            />
                            <path
                                className="fil0"
                                d="M13.28 14.8c0,0.36 0.03,0.69 0.23,0.97l0.71 0.69c0.11,0.09 0.14,0.13 0.24,0.22 0.06,0.05 0.07,0.05 0.13,0.1 0.22,0.18 0.39,0.38 0.61,0.56 0.21,0.17 0.97,0.91 1.21,1.1 0.1,0.08 0.14,0.14 0.23,0.22l1.35 1.21c0.26,0.23 0.59,0.58 0.85,0.78 0.1,0.07 0.14,0.14 0.23,0.22l1.23 1.1c0.54,0.41 1.34,0.35 1.74,-0.14 0.33,-0.4 0.46,-0.98 0.21,-1.47l-0.23 -0.31c-0.06,-0.07 -0.03,-0.03 -0.1,-0.09l-0.39 -0.35c-0.07,-0.07 -0.11,-0.12 -0.19,-0.18 -0.09,-0.07 -0.12,-0.12 -0.21,-0.19l-1.37 -1.22c-0.08,-0.07 -0.12,-0.13 -0.2,-0.2l-1.57 -1.42c-0.21,-0.16 -0.38,-0.36 -0.58,-0.53 -0.08,-0.07 -0.12,-0.09 -0.2,-0.17 -0.08,-0.08 -0.11,-0.1 -0.2,-0.17 -0.13,-0.1 -0.48,-0.5 -0.61,-0.53l0 -0.06c0.09,-0.02 0.32,-0.26 0.41,-0.33l0.38 -0.36c0.3,-0.31 0.66,-0.58 0.97,-0.88 0.08,-0.08 0.12,-0.1 0.2,-0.17 0.07,-0.06 0.11,-0.12 0.19,-0.18l1.18 -1.07c0.08,-0.07 0.12,-0.09 0.2,-0.17l1.37 -1.25c0.32,-0.28 0.7,-0.56 0.92,-0.93 0.3,-0.49 0.16,-1.07 -0.17,-1.49 -0.09,-0.11 -0.22,-0.2 -0.36,-0.27 -0.45,-0.23 -0.97,-0.2 -1.38,0.11l-1.47 1.32c-0.11,0.09 -0.14,0.13 -0.24,0.22l-1.21 1.1c-0.39,0.38 -0.83,0.71 -1.21,1.1l-2.44 2.21c-0.25,0.26 -0.48,0.52 -0.48,0.95z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                }
            },
        ],
    };

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
            <div className="w-full py-4 md:py-8 flex items-center justify-center">
                <div className="w-[75%]">
                    <img src={`${process.env.PUBLIC_URL}/ALMARAY.png`} alt="ALMARAY logo" className="w-full"/>
                </div>
            </div>

            <main className="flex-grow">
                <div className="w-full hidden sm:block">
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={3000}
                        className="w-full custom-carousel"
                    >
                        <img src={`${process.env.PUBLIC_URL}/сайт.jpg`} alt="Image 1" className="w-full"/>
                        <img src="/mainImages/баннер%202.jpg" alt="Image 2" className="w-full"/>
                        <img src="/mainImages/баннер%203.jpg" alt="Image 3" className="w-full"/>
                        <img src="/mainImages/баннер%204.jpg" alt="Image 4" className="w-full"/>
                        <img src="/mainImages/баннер%205.jpg" alt="Image 5" className="w-full"/>
                        <img src="/mainImages/баннер%206.jpg" alt="Image 6" className="w-full"/>
                    </Carousel>
                </div>

                <div className="w-full hidden 2xl:block  bg-white md:bg-grayLight">
                    <h1 className="font-bold text-6xl py-6">ХИТ</h1>

                    <div className="product-carousel-container overflow-hidden">
                        <div className="product-track flex">
                            {products.slice(0, 9).map((product, index) => (
                                <div key={index} className="product-slide flex-shrink-0 w-1/5 p-1 cursor-pointer" onClick={() => navigate(`/products/${product.id}`)}>
                                    <div className="product-card">
                                        <div className="bg-white p-4">
                                            <img src={`https://back.almaray.kz/images/${product.picture_path}`} alt={product.name} className="w-full h-48 object-contain"/>
                                        </div>
                                        <div className="product-info mt-2">
                                            <span className="block">{product.catalog_name}</span>
                                            <span className="block">Арт: {product.article}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="container mx-auto px-4  bg-white md:bg-grayLight p-10 hidden 2xl:flex items-center justify-center">
                    <Slider {...settings} className={"w-[80%]"}>
                        {loading ? (
                            Array(3).fill(0).map((_, index) => (
                                <div key={index} className="p-4">
                                    <div className="bg-white p-4 rounded shadow">
                                        <Skeleton height={192} />
                                        <p className="text-center mt-2">
                                            <Skeleton width={100} />
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            products.slice(9).map(product => (
                                <div key={product.id} className="p-4 cursor-pointer" onClick={() => navigate(`/products/${product.id}`)}>
                                    <div className="bg-white p-4 rounded shadow">
                                        <img src={`https://back.almaray.kz/images/${product.picture_path}`} alt={product.name} className="mx-auto"/>
                                        <p className="text-center mt-2">{product.name}</p>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>
                </div>

                <div className="w-full hidden sm:block">
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={3000}
                        className="w-full custom-carousel"
                    >
                        <img src="/mainImages/баннер%208.jpg" alt="Image 3" className="w-full"/>
                        <img src="/mainImages/баннер%2010.jpg" alt="Image 2" className="w-full"/>
                        <img src="/mainImages/баннер%209.jpg" alt="Image 4" className="w-full"/>
                    </Carousel>
                </div>

                <div className={"w-full hidden md:flex items-center justify-center  bg-white md:bg-grayLight"}>
                    <div className={"w-[80%]"}>
                        <div className={"w-full h-[400px] flex items-center justify-between"}>
                            <div className={"w-[48%]"}>
                                <img src="/mainImages/обручальные%20кольца.png" alt="" className={"w-full h-full"}/>
                            </div>
                            <div className={"w-[48%]"}>
                                <img src="/mainImages/помолвочные%20кольца.png" alt="" className={"w-full h-full"}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"w-full flex items-center justify-center  bg-white md:bg-grayLight"}>
                    <div className={"w-[95%] 2xl:w-[80%] flex flex-col md:flex-row items-center justify-between mx-auto"}>
                        <div className={"w-full md:w-[30%]"}>
                            <img src="/mainImages/фото%2011.jpg" alt=""/>
                        </div>

                        <div className="w-full md:w-1/4 flex flex-col items-center p-5">
                            <h1 className="w-full text-justify text-black font-bold text-4xl mb-3 md:mb-10">
                                Коллекция «Наследие степей»
                            </h1>
                            <p className="w-full text-justify text-lg">
                                Особая гордость - коллекция в национальном стиле, где мы были вдохновлены обычаями и
                                традициями Казахского народа, бескрайними степями, национальным орнаментом,
                                чарующими мелодиями домбры и кобыза.
                            </p>
                        </div>

                        <div className={"w-full md:w-[40%] flex h-full"}>
                            <div className={"w-1/2 h-full flex flex-col"}>
                                <div className={"h-1/2 mb-5"}>
                                    <img src="/mainImages/кольцо%204.jpg" alt=""/>
                                </div>
                                <div className={"h-1/2"}>
                                    <img src="/mainImages/кольцо%205.jpg" alt=""/>
                                </div>
                            </div>
                            <div className={"w-1/2 h-full ml-4"}>
                                <img src="/mainImages/серьги%204.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"w-full flex items-center justify-center  bg-white md:bg-grayLight"}>
                    <div className={"w-[80%] mt-10"}>
                        <div className={"w-full flex items-center justify-start "}>
                            <h1 className={"text-customGray text-2xl border-b-2 border-black font-bold"}>
                                Все Коллекции
                            </h1>
                        </div>

                        <div
                            className={
                                "w-full mt-4 flex flex-col items-center justify-center md:flex-row md:justify-around mb-10"
                            }
                        >
                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>«Lantana»</h1>
                                <img src="/mainImages/кольцо%204.jpg" alt="" />
                            </div>

                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>«Золотой дуэт»</h1>
                                <img src="/mainImages/золотой%20дуэт.jpg" alt="" />
                            </div>

                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>««Удача»»</h1>
                                <img src="/mainImages/удача.jpg" alt="" />
                            </div>

                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>«Modern»</h1>
                                <img src="/mainImages/модерн.jpg" alt="" />
                            </div>

                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>«Узел любви»</h1>
                                <img src="/mainImages/узел%20любви.jpg" alt="" />
                            </div>

                            <div className={"w-2/3 md:w-1/5 mr-10 flex flex-col bg-white"}>
                                <h1 className={"bg-white md:bg-grayLight font-bold text-xl"}>«Геометрия»</h1>
                                <img src="/mainImages/геометрия.jpg" alt="" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"w-full"}>
                    <img src="/mainImages/баннер%2011.jpg" alt=""/>
                </div>
            </main>
        </div>
    );
};

export default Main;