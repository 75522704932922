import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Products from "./veiws/Products/Products";
import Main from "./veiws/Main/Main";
import Login from "./veiws/Login/Login";
import Me from "./veiws/Me/Me";
import Favourites from "./veiws/Favourites/Favourites";
import PrivateRoute from "./config/PrivateRoute";
import OrderSuccess from "./veiws/OrderSuccess/OrderSuccess";
import Basket from "./veiws/Basket/Basket";
import ProductInfo from "./veiws/ProductInfo/ProductInfo";
import ConsultantRoute from "./config/ConsultantRoute";
import Consultant from "./veiws/Consultant/Consultant";
import Ringsizer from "./veiws/Ringsizer/Ringsizer";
import OrderDetailsComponent from "./veiws/Me/components/ChieldComponents/OrderDetailsComponent";
import { AuthUserContext } from "./contexts/AuthUserContext";
import Shops from "./veiws/Shops/Shops";
import Brands from "./veiws/Brands/Brands";
import Delivery from "./veiws/Delivery/Delivery";
import Questions from "./veiws/Questions/Questions";
import Exchange from "./veiws/Exchange/Exchange";
import AboutBrand from "./veiws/AboutBrand/AboutBrand";
import News from "./veiws/News/News";
import Cooperation from "./veiws/Cooperation/Cooperation";
import BonusProgram from "./veiws/BonusProgram/BonusProgram";
import AboutJewelry from "./veiws/AboutJewelry/AboutJewelry";
import Encyclopedia from "./veiws/Encyclopedia/Encyclopedia";
import FeedBack from "./veiws/Feedback/FeedBack";
import PrivacyPolicy from "./veiws/PrivacyPolicy/PrivacyPolicy";
import CheckingPriceBySeries from "./veiws/CheckingPriceBySeries/CheckingPriceBySeries";

const AppRoutes = () => {
    const { authUser } = useContext(AuthUserContext);

    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductInfo />} />
            <Route path="/orderSuccess" element={<OrderSuccess />} />
            <Route path="/ringsizer" element={<Ringsizer />} />
            <Route path={"/shops"} element={<Shops/>} />
            <Route path={"/brands"} element={<Brands/>} />
            <Route path={"/delivery"} element={<Delivery/>} />
            <Route path={"/questions"} element={<Questions/>} />
            <Route path={"/exchange"} element={<Exchange/>} />
            <Route path={"/about-brand"} element={<AboutBrand/>} />
            <Route path={"/news"} element={<News/>} />
            <Route path={"/cooperation"} element={<Cooperation/>} />
            <Route path={"/bonus-program"} element={<BonusProgram/>} />
            <Route path={"/about-jewelry"} element={<AboutJewelry/>} />
            <Route path={"/encyclopedia"} element={<Encyclopedia/>} />
            <Route path={"/feedback"} element={<FeedBack/>} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy/>} />
            <Route path={"/checking-price-by-series"} element={<CheckingPriceBySeries/>} />

            <Route path="/consultant/*" element={<ConsultantRoute />}>
                <Route path="consultant-dashboard" element={<Consultant />} />
            </Route>
            <Route
                path="/orders/:id"
                element={
                    <PrivateRoute authUser={authUser}>
                        <OrderDetailsComponent />
                    </PrivateRoute>
                }
            />
            <Route
                path="/favourites"
                element={
                    <PrivateRoute authUser={authUser}>
                        <Favourites />
                    </PrivateRoute>
                }
            />
            <Route
                path="/basket"
                element={
                    <PrivateRoute authUser={authUser}>
                        <Basket />
                    </PrivateRoute>
                }
            />
            {authUser ? (
                <Route path="/me" element={<Me />} />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
        </Routes>
    );
};

export default AppRoutes;