import React from 'react';

const AboutBrand = () => {
    return (
        <div>
            О бренде

        </div>
    );
};

export default AboutBrand;