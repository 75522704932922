import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='container text-left'>
            <h2>Общие положения</h2>
            <p>Данная Политика используется и применима исключительно к Персональным данным, получаемым от Пользователя в связи с использованием им Мобильного приложения. Положения данной Политики направлены на:</p>
            <p>(1) определение видов и типов получаемых Персональных данных, направлений и целей использования (обработки) Персональных данных, а также источников получения таких Персональных данных; и</p>
            <p>(2) определение прав Пользователя в отношении защиты конфиденциальности передаваемых им Персональных данных; и</p>
            <p>Посредством установки и/или активации Мобильного приложения на смартфоне, планшете, или другом мобильном устройстве Пользователь соглашается с условиями данной Политики и дает свое согласие Правообладателю на сбор, обработку, удержание и хранение Персональных данных в порядке и на условиях, предусмотренных настоящей Политикой.</p>
            <p>Если Пользователь не согласен с условиями Политики и/или отдельные условия Политики ему не понятны, в таком случае Пользователь обязан немедленно прекратить использование Мобильного приложения.</p>
            <h2>Права пользователя по защите персональных данных</h2>
            <p>В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:</p>
            <p>(1) получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки, сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или Закона).</p>
            <p>(2) получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку Персональных данных.</p>
            <p>(3) получать данные о сроках хранения Персональных данных.</p>
            <p>(4) получать данные об осуществленной или о предполагаемой трансграничной передаче Персональных данных.</p>
            <p>(5) обжаловать действия или бездействие Правообладателя в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</p>
        </div>
    );
};

export default PrivacyPolicy;