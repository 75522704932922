import React, {useContext, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import Loader from '../../components/Loader';
import {useLocation, useNavigate} from 'react-router-dom';
import { CatalogContext } from '../../contexts/CatalogContext';
import Sidebar from "../../layout/Sidebar";
import axiosClient from "../../config/axios-interceptors";
import useProductsStore from "../../store/ProductStore";

const Catalog = () => {
    const [catalogs, setCatalogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { catalogId, setCatalogId, isOpenCatalog, setIsOpenCatalog} = useContext(CatalogContext);
    const { goldType, setGoldType } = useProductsStore();
    const location = useLocation()
    const golds = [
        'Желтое золото',
        'Красное золото'
    ]

    const goldMap = useMemo(() => ({
        1: '/golds/желтое золото_ImgID1.png',
        2: '/golds/красное золото_ImgID1.png',
    }), []);

    useEffect(() => {
        setLoading(true);
        axiosClient.get('/catalogs').then(response => {
            console.log(response.data.data);
            setCatalogs(response.data.data);
            setLoading(false);
        });
    }, []);

    const handleClick = (id) => {
        setCatalogId(id);
        setIsOpenCatalog(!isOpenCatalog);
        navigate('/products');
        setGoldType('');
        localStorage.setItem('catalogId', id);
        localStorage.removeItem('goldType');
    };

    const handleGoldClick = (gold) => {
        const type = gold === 'Желтое золото' ? 'yellowgold' : 'redgold';
        setGoldType(type);
        setCatalogId(null);
        setIsOpenCatalog(!isOpenCatalog);
        navigate('/products');
        localStorage.setItem('goldType', type);
    };
    
    return (
      <div
        className={`w-full h-screen flex pt-0 sm:pt-4 overflow-hidden px-0 2xl:px-[7%]`}
      >
        <Sidebar />
        <main className="flex-1 p-4 overflow-y-auto h-full px-4 2xl:pl-20">
          <div className="flex flex-col md:grid grid-cols-2 gap-4 mb-4">
            <div className="bg-gray-300 flex items-center justify-center h-44 rounded-3xl text-3xl font-medium">
              <img src="/mainImages/обручальные%20кольца.png" alt="" className={"w-full h-full"}/>
            </div>
            <div className="bg-gray-300 flex items-center justify-center h-44 rounded-3xl text-3xl font-medium">
              <img src="/mainImages/помолвочные%20кольца.png" alt="" className={"w-full h-full"}/>
            </div>
          </div>

          <div className="text-gray-500 mb-2 flex flex-start 2xl:mb-4">Тип украшения -</div>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-16 mb-4 text-gray-700 p-6 sm:px-10">
            {loading ? (
              <Loader />
            ) : (
              catalogs.map((catalog) => (
                <div
                  key={catalog.id}
                  className="text-2xl 2xl:text-xl hover:cursor-pointer flex flex-col sm:flex-row items-center justify-center"
                  onClick={() => handleClick(catalog.id)}
                >
                  <img
                    src={`https://back.almaray.kz/images/${catalog.first_product.picture_path}`}
                    alt={catalog.name}
                    className="h-16 2xl:h-20 mr-2 object-cover mb-2"
                  />
                  <span className={'text-md text-customGray ml-2'}>{catalog.name}</span>
                </div>
              ))
            )}
          </div>

          <div className="text-gray-500 mb-2 flex flex-start">Материал -</div>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-16 mb-4 text-gray-700">
            {loading ? (
              <Loader />
            ) : (
              golds.map((gold, index) => (
                <div key={index} className="text-2xl 2xl:text-xl     mx-10 hover:cursor-pointer" onClick={() => handleGoldClick(gold)}>
                  <img src={goldMap[index + 1]} alt={gold} className="w-full h-36 object-cover mb-2 rounded-3xl" />
                  <h1 className={'ml-2 text-md text-customGray'}>{gold}</h1>
                </div>
              ))
            )}
          </div>
        </main>
      </div>
    );
};

export default Catalog;
