import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export const ImageGallery = ({ images, onSelectImage }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        onSelectImage(images[currentIndex]);
    }, [currentIndex, images, onSelectImage]);

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(newIndex);
        onSelectImage(images[newIndex]);
    };

    const getImageAtIndex = (index) => {
                return images[(index + images.length) % images.length];
    };

    return (
        <div className="flex items-center space-x-3">
            {[-1, 0, 1].map((offset) => (
                <motion.div
                    key={offset}
                    className={`w-24 h-24 sm:w-36 sm:h-36 border-2 ${offset === 0 ? 'border-customGray' : 'border-customGray'} rounded-xl overflow-hidden`}
                    initial={{ opacity: 0, x: offset === 0 ? 0 : offset > 0 ? 50 : -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <motion.img
                        src={'https://back.almaray.kz/images/'+getImageAtIndex(currentIndex + offset)}
                        alt={`Product ${offset + 2}`}
                        className={`w-full h-full object-cover cursor-pointer ${offset === 0 ? 'ring-2 ring-blue-500' : ''}`}
                        onClick={() => {
                            const newIndex = (currentIndex + offset + images.length) % images.length;
                            setCurrentIndex(newIndex);
                            onSelectImage(images[newIndex]);
                        }}
                        whileTap={{ scale: 0.9 }}
                    />
                </motion.div>
            ))}
            <motion.button
                className="p-2 flex items-center justify-center bg-customGray rounded-xl"
                onClick={handleNext}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    width="7mm"
                    height="7mm"
                    version="1.1"
                    style={{
                        shapeRendering: 'geometricPrecision',
                        textRendering: 'geometricPrecision',
                        imageRendering: 'optimizeQuality',
                        fillRule: 'evenodd',
                        clipRule: 'evenodd',
                    }}
                    viewBox="0 0 105.76 141.2"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
                >
                    <defs>
                        <style type="text/css">
                            {`
          .fil0 { fill: #666666 }
        `}
                        </style>
                    </defs>
                    <g id="Слой_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer"/>
                        <g id="_2284757140768">
                            <path
                                className="fil0"
                                d="M105.76 69.21c0,3.44 -0.28,6.71 -2.2,9.39l-6.86 6.65c-1.04,0.83 -1.37,1.23 -2.31,2.1 -0.56,0.52 -0.68,0.51 -1.23,0.97 -2.08,1.74 -3.82,3.72 -5.91,5.39 -2.05,1.64 -9.38,8.85 -11.7,10.64 -0.95,0.73 -1.4,1.4 -2.26,2.16l-13.1 11.73c-2.48,2.19 -5.75,5.64 -8.21,7.51 -0.94,0.72 -1.4,1.33 -2.27,2.14l-11.95 10.67c-5.26,3.99 -12.99,3.36 -16.87,-1.31 -3.24,-3.9 -4.45,-9.53 -2.02,-14.27l2.22 -3.02c0.58,-0.66 0.33,-0.33 1.01,-0.92l3.75 -3.43c0.71,-0.72 1.09,-1.14 1.86,-1.73 0.85,-0.65 1.2,-1.13 2,-1.87l13.24 -11.86c0.73,-0.64 1.19,-1.29 1.95,-1.92l15.21 -13.76c2.05,-1.58 3.69,-3.46 5.61,-5.15 0.78,-0.69 1.15,-0.86 1.93,-1.66 0.79,-0.81 1.07,-0.96 1.92,-1.67 1.22,-1.02 4.68,-4.84 5.87,-5.16l0 -0.55c-0.9,-0.24 -3.11,-2.52 -3.93,-3.24l-3.73 -3.44c-2.89,-2.96 -6.43,-5.59 -9.37,-8.56 -0.77,-0.78 -1.19,-0.95 -1.94,-1.65 -0.67,-0.62 -1.1,-1.2 -1.8,-1.78l-11.43 -10.36c-0.75,-0.72 -1.19,-0.9 -1.94,-1.64l-13.24 -12.14c-3.1,-2.73 -6.79,-5.4 -8.95,-8.98 -2.89,-4.78 -1.55,-10.4 1.67,-14.44 0.85,-1.06 2.13,-1.97 3.44,-2.63 4.37,-2.19 9.41,-1.93 13.4,1.05l14.22 12.81c1.07,0.82 1.37,1.3 2.3,2.11l11.72 10.63c3.75,3.72 8,6.87 11.72,10.62l23.59 21.37c2.46,2.47 4.62,5.06 4.62,9.17z"
                            />
                            <path
                                className="fil0"
                                d="M43.05 69.92c0,1.68 -0.14,3.27 -1.07,4.58l-3.35 3.24c-0.51,0.4 -0.67,0.6 -1.13,1.02 -0.27,0.25 -0.33,0.25 -0.6,0.48 -1.02,0.85 -1.86,1.82 -2.88,2.63 -1,0.8 -4.58,4.32 -5.71,5.19 -0.46,0.36 -0.68,0.68 -1.1,1.05l-6.39 5.72c-1.21,1.07 -2.81,2.75 -4,3.66 -0.46,0.35 -0.68,0.65 -1.11,1.04l-5.83 5.21c-2.57,1.94 -6.34,1.64 -8.23,-0.64 -1.58,-1.9 -2.17,-4.65 -0.98,-6.96l1.08 -1.47c0.28,-0.32 0.16,-0.16 0.49,-0.45l1.83 -1.67c0.35,-0.35 0.53,-0.56 0.91,-0.84 0.42,-0.32 0.59,-0.55 0.97,-0.91l6.46 -5.78c0.36,-0.31 0.58,-0.63 0.95,-0.93l7.42 -6.71c1,-0.77 1.8,-1.69 2.74,-2.51 0.38,-0.34 0.56,-0.42 0.94,-0.81 0.39,-0.4 0.52,-0.47 0.93,-0.81 0.59,-0.5 2.28,-2.36 2.87,-2.52l0 -0.27c-0.44,-0.12 -1.52,-1.23 -1.92,-1.58l-1.82 -1.68c-1.41,-1.44 -3.14,-2.73 -4.57,-4.17 -0.38,-0.38 -0.58,-0.47 -0.95,-0.8 -0.33,-0.3 -0.54,-0.58 -0.88,-0.87l-5.57 -5.05c-0.37,-0.35 -0.58,-0.44 -0.95,-0.8l-6.46 -5.92c-1.51,-1.33 -3.31,-2.63 -4.37,-4.38 -1.41,-2.33 -0.76,-5.07 0.81,-7.05 0.41,-0.52 1.04,-0.96 1.68,-1.28 2.13,-1.07 4.59,-0.94 6.53,0.51l6.93 6.25c0.52,0.4 0.67,0.63 1.12,1.03l5.71 5.18c1.83,1.81 3.9,3.35 5.72,5.18l11.51 10.42c1.2,1.21 2.25,2.47 2.25,4.47z"
                            />
                        </g>
                    </g>
                </svg>
            </motion.button>
        </div>
    );
};

export default ImageGallery;