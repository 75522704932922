import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from '../../contexts/AuthUserContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from "../../config/axios-interceptors";

const Login = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const [phone, setPhone] = useState(() => {
        const savedPhone = localStorage.getItem('savedPhone');
        return savedPhone || '+7';
    });
    const [verifyCode, setVerifyCode] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [sended, setSended] = useState(false);
    const [lastSentTime, setLastSentTime] = useState(null);
    const [showResendButton, setShowResendButton] = useState(true);
    const [showChangeNumberButton, setShowChangeNumberButton] = useState(true);
    const [countdown, setCountdown] = useState(60);

    const navigate = useNavigate();

    useEffect(() => {
        const storedLastSentTime = localStorage.getItem('lastSentTime');
        const storedSended = localStorage.getItem('sended');

        if (storedLastSentTime) {
            setLastSentTime(parseInt(storedLastSentTime, 10));
        }

        if (storedSended === 'true') {
            setSended(true);
        }
    }, []);

    useEffect(() => {
        let timer;
        if (lastSentTime) {
            timer = setInterval(() => {
                const now = Date.now();
                const diff = now - lastSentTime;
                if (diff >= 60000) {
                    setShowResendButton(true);
                    setShowChangeNumberButton(true);
                    clearInterval(timer);
                } else {
                    setCountdown(60 - Math.floor(diff / 1000));
                }
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [lastSentTime]);

    const handleClick = async () => {
        if (phone.length <= 2) {
            return false;
        }
        const currentTime = Date.now();
        const timeSinceLastSend = currentTime - (lastSentTime || 0);
        const cooldownPeriod = 60000;

        if (timeSinceLastSend < cooldownPeriod) {
            toast.error('Пожалуйста, подождите перед повторной отправкой');
            return false;
        }

        setDisabled(true);
        try {
            const response = await axiosClient.post('/verify2', { phone: phone });
            if (response) {
                setSended(true);
                setVerifyCode('');
                setLastSentTime(currentTime);
                localStorage.setItem('lastSentTime', currentTime);
                localStorage.setItem('sended', 'true');
                localStorage.setItem('savedPhone', phone);
                setShowResendButton(false);
                setShowChangeNumberButton(false);
                setCountdown(60);
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error('Ошибка при отправке кода');
        } finally {
            setDisabled(false);
        }
    };

    const handleCodeVerifyClick = async () => {
        if (verifyCode.length <= 0) {
            toast.error('Пожалуйста, введите код подтверждения');
            return false;
        }
        setDisabled(true);
        try {
            const response = await axiosClient.post('/login2', { phone: phone, code: verifyCode });
            if (response.data && response.data.token) {
                const userData = {
                    id: response.data.user_id,
                    phone: phone,
                    token: response.data.token,
                    role: response.data.role
                };
                localStorage.setItem('user', JSON.stringify(userData));
                setAuthUser(userData);
                toast.success('Успешный вход');
                navigate('/');
            } else {
                throw new Error('Token not received');
            }
        } catch (error) {
            toast.error('Ошибка при проверке кода');
        } finally {
            setDisabled(false);
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (value.startsWith('+7')) {
            setPhone(value);
            localStorage.setItem('savedPhone', value);
        }
    };

    const handleCodeChange = (e) => {
        const code = e.target.value.replace(/\D/g, '').slice(0, 6);
        setVerifyCode(code);
    };


    const handleResend = () => {
        handleClick();
        setShowResendButton(false);
        setShowChangeNumberButton(false);
    };

    const handleChangeNumber = () => {
        setSended(false);
        setShowResendButton(false);
        setShowChangeNumberButton(false);
    };

    return (
        <div className="w-full h-96 flex items-center justify-center">
            <div className="w-[75%] flex items-center justify-center">
                {sended === false ? (
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="flex">
                            <p className="text-customGray text-2xl font-bold ml-2">Войти</p>
                        </div>

                        <input
                            className="border-black px-8 py-3 rounded-2xl border-2 text-black focus:outline-none text-lg mt-2"
                            value={phone}
                            onChange={handlePhoneChange}
                            maxLength="12"
                        />

                        <button
                            disabled={disabled}
                            onClick={handleClick}
                            className={`px-10 py-3 text-xl text-customGray font-bold rounded-xl mt-2 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-customGray bg-opacity-35 text-customGray'}`}
                        >
                            Получить код
                        </button>

                        <span className="text-customGray text-sm mt-2">
                            Нажимая «Получить код», я соглашаюсь с условиями участия в ALMARAY, политикой конфиденциальности и подтверждаю согласие на получение сообщений рекламного характера. Отказаться от рассылки можно в личном кабинете.
                        </span>
                    </div>
                ) : (
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="flex">
                            <div className="flex flex-col items-start">
                                <div className="text-black text-2xl">
                                    На номер телефона -
                                </div>
                                <div className="text-customGray text-2xl">
                                    {phone}
                                </div>
                                <div className="text-black text-2xl mb-4">
                                    был отправлен код
                                </div>
                                <input
                                    type="text"
                                    value={verifyCode}
                                    onChange={handleCodeChange}
                                    className="border-2 border-black rounded-xl p-2 text-center text-2xl w-52 mb-1"
                                    placeholder="--- ---"
                                    maxLength="6"
                                />

                                <button
                                    disabled={disabled}
                                    onClick={handleCodeVerifyClick}
                                    className={`px-10 py-3 text-xl mb-4 text-customGray font-bold rounded-xl mt-2 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-customGray bg-opacity-35 text-customGray'}`}
                                >
                                    Проверить код
                                </button>

                                {showResendButton ? (
                                    <span
                                        className="italic border-b-2 border-black mb-2 hover:text-customGray hover:cursor-pointer"
                                        onClick={handleResend}>
                        Отправить повторно
                    </span>
                                ) : (
                                    <span className="text-gray-500 mb-2">
                        Повторная отправка через {countdown} сек
                    </span>
                                )}

                                {showChangeNumberButton && (
                                    <span
                                        className="italic border-b-2 border-black hover:text-customGray hover:cursor-pointer"
                                        onClick={handleChangeNumber}>
                        Изменить номер телефона
                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={`w-1/2 hidden md:flex items-center justify-center ${sended === false ? 'ml-28' : 'ml-0'}`}>
                    <img src={`${process.env.PUBLIC_URL}/ALMARAY.png`} alt="Описание изображения"
                         className="mx-auto my-4"/>
                </div>
            </div>
        </div>
    );
};

export default Login;