import { create } from 'zustand';
import axiosClient from "../config/axios-interceptors";

const useProductsStore = create((set, get) => ({
    products: [],
    filteredProducts: [],
    loading: false,
    sidebarLoading: false,
    sort: '',
    currentPage: 1,
    totalPages: 1,
    minPrice: 21500,
    maxPrice: 644000,
    selecteds: [],
    goldType: '',
    totalProducts: 0,
    inserts: [],
    catalogId: 0,
    authUser: null,
    setProducts: (products) => set({ products }),
    setFilteredProducts: (filteredProducts) => set({ filteredProducts }),
    setLoading: (loading) => set({ loading }),
    setSidebarLoading: (sidebarLoading) => set({ sidebarLoading }),
    setSort: (sort) => set({ sort }),
    setCurrentPage: (currentPage) => set({ currentPage }),
    setTotalPages: (totalPages) => set({ totalPages }),
    setMinPrice: (minPrice) => set({ minPrice }),
    setMaxPrice: (maxPrice) => set({ maxPrice }),
    setSelecteds: (selecteds) => set({ selecteds }),
    setGoldType: (goldType) => set({ goldType }),
    setTotalProducts: (totalProducts) => set({ totalProducts }),
    setInserts: (inserts) => set({ inserts }),
    setCatalogId: (catalogId) => set({ catalogId }),
    fetchProducts: async (page, userId) => {
        set({ loading: true });
        try {
            let url = `/products?page=${page}`;
            if (userId) {
                url += `&user_id=${userId}`;
            }
            if (get().catalogId ) {
                url += `&catalog_id=${get().catalogId}`;
            }
            if (get().sort) {
                url += `&sort=${get().sort}`;
            }
            if (get().minPrice && get().maxPrice) {
                url += `&min_price=${get().minPrice}&max_price=${get().maxPrice}`;
            }
            if (get().goldType) {
                url += `&gold_type=${get().goldType}`;
            }
            if (get().inserts) {
                url += `&inserts=${get().inserts}`;
            }
            if (get().selecteds.length > 0) {
                url += `&sizes=${get().selecteds.filter((item) => typeof item === 'number').join(',')}`;
            }
            const response = await axiosClient.get(url);
            if (response.data && response.data.data) {
                set({ products: response.data.data, totalProducts: response.data.total, totalPages: response.data.last_page });
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('There was an error fetching the products', error);
        } finally {
            set({ loading: false });
        }
    },
}));

export default useProductsStore;