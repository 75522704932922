import React from 'react';
import BasketCheckbox from './BasketCheckbox';

const BasketProductMobile = ({
  item,
  onDelete,
  onCheckboxChange,
  isChecked,
  active,
  setActive,
  setModalItemId,
  handleClick,
}) => {
  return (
    <div className={'mb-10 sm:hidden'}>
      <div className={'flex'}>
        <div className="relative w-1/2 lg:w-1/2 h-full bg-gray-100 rounded-2xl">
          <BasketCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(item.id)}
            cls={'absolute top-0 left-0 md:inline-flex md:items-center'}
          />
          <img
            className={`max-w-full max-h-full object-contain p-10 ${!isChecked ? 'opacity-50' : ''}`}
            src="/catalogImages/браслеты.png"
            alt={item.product_name}
          />
        </div>
        <div className={'w-1/2'}>
          <h1 className={'text-xl font-bold flex flex-start ml-6 mt-1'}>
            {item.price * item.average_weight * item.quantity} ₸
          </h1>
          <h1 className="text-lg flex flex-start ml-6">{item.product_name.match(/[А-Яа-яЁё]+/)[0]}</h1>
          <h1 className="text-lg flex flex-start ml-6">
            {' '}
            {item.sample ? item.sample.match(/\d+/)?.[0] || 'N/A' : 'N/A'} пробы
          </h1>
          <h1 className="text-lg flex flex-start ml-6 text-gray-500">Арт. {item.article}</h1>
          <div
            className="w-[90%] mt-4 ml-4 flex items-center justify-center hover:cursor-pointer"
            onClick={() => handleClick()}
          >
            <div className="border-2 max-h-16 border-black lg:ml-0 w-full lg:w-[80%] h-1/2 rounded-2xl flex items-center justify-center px-3 py-3">
              <div className="w-full mr-6">
                <h1 className="text-sm text-gray-500 flex flex-start ">Разм. {item.size > 0 ? item.size : 'нет'}</h1>

                <h1 className="text-sm text-gray-500 flex flex-start ">
                  {item.quantity} {item.quantity > 1 ? 'изделия ' : 'изделие'}
                </h1>
              </div>
              <div className="w-1/4 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                  <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'flex items-center justify-between px-4 py-4'}>
        <div className="flex items-center mt-2 hover:cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6 hover:fill-gray-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
          </svg>
          <span className="text-sm text-black ml-1">Купить</span>
        </div>

        <div className="flex items-center mt-2 hover:cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6 hover:fill-gray-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
            />
          </svg>
          <span className="text-sm text-black ml-1">В избранное</span>
        </div>

        <div
          className="flex items-center mt-2 hover:cursor-pointer"
          onClick={() => onDelete(item.product_id, item.size)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6 hover:fill-gray-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
          <span className="text-sm text-black ml-1 hover:text-gray-400">Удалить</span>
        </div>
      </div>
    </div>
  );
};

export default BasketProductMobile;
