import React from 'react';

const FeedBack = () => {
    return (
        <div>
            Обратная связь
        </div>
    );
};

export default FeedBack;