import React from 'react';

const BonusProgram = () => {
    return (
        <div>
            Бонусная программа
        </div>
    );
};

export default BonusProgram;